import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollMemory from "./components/react-router-scroll-memory/ScrollMemory";
import lazy from "react-lazy-with-preload";
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { PeluchanContext } from "./contexts/PeluchanContext/index";
import { EnvironmentContext } from "./contexts/EnvironmentContext";

import "./utils/logs/logs";

import Body from "./pages/Document";
import Popup from "./components/utils/popup";
import { HistoryContext } from "./hooks/useHistory";
import { QueryParamProvider } from "use-query-params";
import Fancybox from "./components/fancybox";
import SnowStorm from "react-snowstorm";
import { TagIs } from "./contexts/EnvironmentContext/useTags";
import { LibRequestProvider } from "./contexts/ReactResquestHook";
import { SocketProvider } from "./contexts/SocketContext";
import { NotifyProvider } from "./contexts/NotifyContext";
import { SetterContentProvider } from "./components/desktop/header/components/SetterContent";
import NavigationComponent from "./components/utils/NavigationComponent";
import { ProviderTheme } from "./contexts/ThemeContext";
import { Memo } from "./components/utils/Memo";

const Home = lazy(() => import("./pages/home/home"));
const Mi = lazy(() => import("./pages/mi"));
const Post = lazy(() => import("./pages/post/article"));
const Search = lazy(() => import("./pages/search/index"));
const Shout = lazy(() => import("./pages/shout"));
const User = lazy(() => import("./pages/user/profile"));
const P404 = lazy(() => import("./pages/p404"));
const Editor = lazy(() => import("./pages/post/Editor/editor"));
const Notifications = lazy(() => import("./pages/notifications"));
const Mensajes = lazy(() => import("./pages/mensajes"));
const Categorys = lazy(() => import("./pages/post/categorys"));
const CategoryUff = lazy(() => import("./pages/post/category_uff"));

const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const Setting = lazy(() => import("./pages/user/Setting/setting"));
const Protocolo = lazy(() => import("./pages/protocolo"));
const Terminos = lazy(() => import("./pages/terminos"));
const Emoticones = lazy(() => import("./pages/emoticones"));
const Info = lazy(() => import("./pages/info/info"));
const Admin = lazy(() => import("./admin"));

// function A25demayo(){
//     const day = dayjs("2021-10-10 14:48");
//     const now = dayjs(new Date());
//     const left = day.diff(now);
//     const timeleft = left-1000;
//     const [count,setCount] = useState(0);
//     useEffect(()=>{
//         const stopme = setTimeout(e=> setCount(count+1), timeleft + 1000);
//         return ()=> clearTimeout(stopme);
//     },[])
//     let timevideo = Math.round(-timeleft/1000);
//     const show = 0 < timevideo && timevideo < 230;
//     if(timevideo<10) timevideo = 0;
//     return <Fragment>
//         {!show?null:<iframe style={{display: "none"}} width="10" height="10" src={`https://www.youtube-nocookie.com/embed/OqSQo2aifAA?controls=0&autoplay=1&amp;start=${timevideo}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
//     </Fragment>;
// }

const Preload = (...args: ReturnType<typeof lazy>[]) => {
    setTimeout(() => args.slice(1).map((i) => i.preload()), 1000);
    return args[0];
};

const PageLoading = () => {
    return (
        <div className="Ul1uoIs3R6">
            {/* <div className="lds-ring"><div></div><div></div><div></div><div></div></div> */}
        </div>
    );
};

const ROUTES: React.FC<React.PropsWithChildren<{}>> = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />

            <Route path="home/*" element={<Home />} />
            <Route path="mi/*" element={<Mi />} />
            <Route path="shout/:shout_id" element={<Shout />} />

            <Route path="post/:post/:slug/editar" element={<Editor />} />
            <Route path="post/:category" element={<Categorys />} />
            <Route path="post/:post/:slug" element={<Post />} />
            <Route path="post/uff/*" element={<CategoryUff />} />

            <Route path="user/:username/*" element={<User />} />
            <Route path="user/:user/:username/setting/*" element={<Setting />} />
            <Route path="user/:userId/:username/*" element={<User />} />

            <Route path="search" element={<Search />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="mensajes" element={<Mensajes />} />
            <Route path="category" element={<Categorys />} />
            <Route path="editor" element={<Editor />} />
            <Route path="terms-and-conditions" element={<Terminos />} />
            <Route path="protocolo" element={<Protocolo />} />
            <Route path="emoticones" element={<Emoticones />} />
            <Route path="admin/*" element={<Admin />} />
            <Route path="info/*" element={<Info />} />

            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />

            <Route path="/page-404" element={<P404 />} />
            <Route path="/*" element={<Navigate to="/page-404" />} />
        </Routes>
    );
};


const Memo_EnvironmentContext = React.memo(EnvironmentContext)
const Memo_SocketProvider = React.memo(SocketProvider)
const Memo_PeluchanContext = React.memo(PeluchanContext)
const Memo_ProviderTheme = React.memo(ProviderTheme)
const Memo_SetterContentProvider = React.memo(SetterContentProvider)
const Memo_LibRequestProvider = React.memo(LibRequestProvider)
const Memo_BrowserRouter = React.memo(BrowserRouter)
const Memo_NotifyProvider = React.memo(NotifyProvider)
const Memo_QueryParamProvider = React.memo(QueryParamProvider)

const CONTEXT: React.FC<React.PropsWithChildren> = ({ children }) => {

    return (
        <Memo_EnvironmentContext>
            <Memo_SocketProvider>
                <Memo_PeluchanContext>
                    {/** @ts-ignore */}
                    <Memo_ProviderTheme>
                        {/** @ts-ignore */}
                        <Memo_SetterContentProvider>
                            <Memo_LibRequestProvider>
                                <Memo_BrowserRouter>
                                    <Memo_NotifyProvider>
                                        <Memo_QueryParamProvider adapter={ReactRouter6Adapter}>
                                            {children}
                                        </Memo_QueryParamProvider>
                                    </Memo_NotifyProvider>
                                </Memo_BrowserRouter>
                            </Memo_LibRequestProvider>
                        </Memo_SetterContentProvider>
                    </Memo_ProviderTheme>
                </Memo_PeluchanContext>
            </Memo_SocketProvider>
        </Memo_EnvironmentContext>
    );
};

function Routing() {

    return (
        <CONTEXT>
            <Memo debs={[]}>
                {() => (<>
                    <ScrollMemory />
                    <HistoryContext />
                    <Fancybox />
                    <NavigationComponent />
                    {/* <ScrollToTop/> */}
                    <Popup />
                    {/* <A25demayo/> */}
                    {/* <Banner/> */}
                    <TagIs id="fechasNavidad">
                        <SnowStorm flakesMax={10} />
                    </TagIs>
                    <Body>
                        <Suspense fallback={<PageLoading />}>
                            <ROUTES />
                        </Suspense>
                    </Body>
                </>)}
            </Memo>
        </CONTEXT>
    );
}

export default Routing;
