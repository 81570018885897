import React from 'react';

import EditorList from "../EditorList/editorlist";

class SendMessage extends React.Component {
    constructor() {
        super();
        this.message = {};
    }

    render() {
        const { parent, parent_type } = this.props;
        const _this = this;
        var message = "";
        //var body = null;
        const dlist = [{
            key: "spam",
            text: "Spam"
        }, {
            key: "nudes",
            text: "Desnudos"
        }, {
            key: "ilegal",
            text: "Contenido ilegal"
        }, {
            key: "molesto",
            text: "Contenido para molestar"
        }, {
            key: "otro",
            text: "Otro"
        }];
        var causa = "spam";
        return (
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{`Denunciar`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                window.popup.close();
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select className="custom-select d-block w-100 mb-2" id="category" required onChange={(e) => {
                                if (!e) return;
                                causa = e.target.value;
                            }}>
                                {dlist.map((e) => {
                                    return (
                                        <option key={e.key} value={e.key}>{`${e.text}`} </option>
                                    );
                                })}
                            </select>
                            <EditorList ref={(e) => {
                                _this.editorlist = e;
                            }} editor="text" defaultValue='' onChange={(body) => {
                                message = body.content;
                            }}></EditorList>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                window.popup.close();
                            }}>Cancelar</button>
                            <button type="button" className="btn btn-primary" onClick={async () => {
                                const l = await window.RPOST("/api/denunciado", {
                                    parent: {
                                        _id: parent._id,
                                        type: parent_type,
                                        causa: causa,
                                        text: message ? message : undefined
                                    }
                                });
                                // if(!_this.editorlist) return;
                                // _this.editorlist.clear();
                                if (l) window.popup.close();
                            }} >Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SendMessage;