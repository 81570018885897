import React from 'react';

import Tinymce from "../utils/tinymce";
import { BucketNames } from '../../utils/services/PeluchanCDN';

class EditorList extends React.Component<{
    defaultValue?: string,
    autoFocus?:boolean
    editor?: "tinymce-comment" | "tinymce"| "tinymce" | "text",
    onChange?: (v:{type: string, content:any })=> void,
    onLoad?: (t:EditorList)=> void,
    placeholder?: string,
    tinymceProps?: {
        uploadBucketName?: BucketNames
    }
}> {
    editor:any

    clear() {
        const { editor, onChange = () => { } } = this.props;

        if (editor === "tinymce-comment") this.editor.clear();
        if (editor === "tinymce") this.editor.clear();
        if (editor === "text") if (this.editor) this.editor.value = "";
    }

    toEnd() {
        const { editor } = this.props;

        if (editor === "tinymce-comment" || editor === "tinymce") this.editor.toEnd();
        //if(editor === "text") if(this.editor) this.editor.value = "";
    }

    push(content, off) {
        const { editor } = this.props;
        if (editor === "tinymce-comment" || editor === "tinymce") this.editor.push(content, off);
    }

    render() {
        const { editor = "text", onChange = () => { }, defaultValue = "", autoFocus, onLoad = () => false, placeholder = "Agrega un comentario", tinymceProps={} } = this.props;

        if (editor === "tinymce-comment")
            return <Tinymce {...tinymceProps} defaultValue={ defaultValue }
        ref = {(e:any) => { this.editor = e; if (autoFocus && e && e.editor) e.editor.focus(); }} 
        onLoad = {(e) => { onLoad(this) }} mode = "Comment" onChange = {(body) => {
            onChange(body);
        }} />;

        if (editor === "tinymce")
            return <Tinymce {...tinymceProps} defaultValue={ defaultValue } ref = {(e:any) => { this.editor = e; if (autoFocus && e && e.editor) e.editor.focus(); }} onLoad = {(e) => { onLoad(this) }} onChange = {(body) => {
            onChange(body);
        }} />;

        if (editor === "text")
            return (
                <textarea ref= {(e) => { 
                    this.editor = e; 
                    onLoad(this);
                    if(e) e.value = defaultValue
                 }} placeholder = { placeholder } className = "form-control editor_raw" autoFocus = { autoFocus? true: false } rows = {3} onChange = {(e) => {
            if (!e) return;
            onChange({ type: "text", content: e.target.value });
        }}> </textarea>
                    );

        return null;
    }
}

export default EditorList;