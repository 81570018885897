import { Base64 } from "js-base64";

type DataType<T = unknown> = {
    type: string,
    content: T
}

class BaseTemplante {

}

function encode(data: any) {
    return Base64.encode(JSON.stringify({ data }))
}
function decode(data: string) {
    try {
        const v = JSON.parse(Base64.decode(data))
        if (!v) return undefined
        return v.data
    } catch (error) {
        return undefined
    }
}

(window as any).tinymce.PluginManager.add('unique2plugin', function (editor) {

    // Función para renderizar el componente visual
    const renderComponent = (data: DataType) => {
        return `<div class="template-component" contenteditable="false" data-type="${data.type}" data-content="${data.content}">
                    awfawf<br/>
                    awfawf
                </div>`;
    };

    // Convierte etiquetas <template> en componentes visuales al cargar
    editor.on('BeforeSetContent', function (event) {
        event.content = event.content.replace(
            /<template data-type="(.*?)" data-content="(.*?)"\s*\/>/g,
            (match, eType, eContent) => {
                return renderComponent({ type: eType, content: eContent })
            }
        );
    });

    // Convierte componentes visuales en etiquetas <template> al guardar
    editor.on('SaveContent', function (event) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = event.content;

        tempDiv.querySelectorAll('.template-component').forEach((el) => {
            const dType = el.getAttribute('data-type');
            const dContent = el.getAttribute('data-content');
            el.outerHTML = `<template data-type="${dType}" data-content="${dContent}" />`;
        });

        event.content = tempDiv.innerHTML;
    });

    // Inserta o edita un componente
    const openDialog = (existingData: any = {}) => {
        editor.windowManager.open({
            title: existingData.xCosa1 ? 'Editar Componente' : 'Insertar Componente',
            body: {
                type: 'panel',
                items: [
                    { type: 'input', name: 'xCosa1', label: 'Valor de xCosa1' },
                    { type: 'input', name: 'xCosa2', label: 'Valor de xCosa2' }
                ]
            },
            buttons: [
                { type: 'cancel', text: 'Cancelar' },
                { type: 'submit', text: 'Guardar' }
            ],
            initialData: {
                xCosa1: existingData.type,
                xCosa2: decode(existingData.content) || ""
            },
            onSubmit: function (api) {
                const data = api.getData();
                const componentHTML = renderComponent({
                    type: data.xCosa1,
                    content: encode(data.xCosa2)
                });

                if (existingData.node) {
                    // Si es edición, reemplaza el nodo actual
                    editor.dom.replace(editor.dom.createFragment(componentHTML), existingData.node);
                } else {
                    // Si es nuevo, inserta el componente
                    editor.insertContent(componentHTML);
                }

                api.close();
            }
        });
    };

    // Botón para insertar un nuevo componente
    editor.ui.registry.addButton('addUnique2', {
        icon: 'plus',
        onAction: function () {
            openDialog();
        }
    });

    // Permite editar el componente al hacer clic en él
    editor.on('click', function (event) {
        const target = event.target;
        if (target.classList.contains('template-component')) {
            openDialog({
                type: target.getAttribute('data-type'),
                content: target.getAttribute('data-content'),
                node: target
            });
        }
    });

    // Estilos personalizados para los componentes
    editor.on('init', function () {
        editor.dom.loadCSS('data:text/css,' + encodeURIComponent(`
            .template-component {
                display: inline-block;
                border: 1px dashed #0073ea;
                padding: 4px;
                margin: 2px;
                border-radius: 3px;
                background-color: #f0f8ff;
                cursor: pointer;
            }
        `));
    });
});

