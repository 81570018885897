import { HOST_IMAGES } from "../../../../api";

const regexContent = new RegExp(`s2:\/\/(.*?)\\.(\w*)`, "g");
const regexSave = new RegExp(`${HOST_IMAGES}\/(.*?)\\.(\w*)`, "g");

(window as any).tinymce.PluginManager.add('s2urlconverter', function (editor) {
    // Hook para interceptar y modificar URLs
    editor.on('BeforeSetContent', function (event) {
        // Convierte 's2://' a '${HOST_IMAGES}/'
        event.content = event.content.replace(
            regexContent,
            `${HOST_IMAGES}/$1.$2`
        );
    });

    editor.on('SaveContent', function (event) {
        // Convierte '${HOST_IMAGES}/' a 's2://'
        event.content = (event.content as string).replace(
            regexSave,
            's2://$1.$2'
        );
        console.log("BB", event.content)
    });

    // Sobrescribe el callback URL converter de TinyMCE
    editor.settings.urlconverter_callback = function (url, node, onSave) {

        if (url.startsWith("s2://")) {
            return url.replace("s2://", `${HOST_IMAGES}/`);
        }
        return url;
    };
});