import React, { FC } from "react";
import { Button } from "../../../../../../../../components/styleguide/components/Button";
import { DynImageContent } from "..";
import { MediaSource } from "../../../../../../../../utils/image";

type ImageSourcePreviewProps = {
    content: DynImageContent,
    onBack: () => void,
}
export const ImageSourcePreview: FC<ImageSourcePreviewProps> = (props) => {
    return (
        <div className="toolbal SelectOption">
            {
                props.content.images.map((obj, index) => {
                    return (
                        <div key={index} className="button_close item_image">
                            <img className="preview_image" src={MediaSource(obj.url).url} onLoad={(e) => {
                                if (!e.target) return;
                                obj.width = (e.target as HTMLImageElement).width;
                                obj.height = (e.target as HTMLImageElement).height;
                            }} />
                            <Button.Icon
                                className="absolute top-0 right-0 !w-[24px] !h-[24px] text-[16px]"
                                onClick={props.onBack}
                                type="flatBlue"
                                icon="close"
                            />
                        </div>
                    );
                })
            }
        </div>
    );
}