import { HOST, HOST_IMAGES_NO_NGINX } from "../../api";
import { PeluchanUpload } from "./PeluchanUpload";

export type UploadCDNType = {
    id: string
    key: string
    baseUrl: string
    varians: Array<string>
    status: number
}


// Función para convertir una imagen base64 en un archivo Blob
export function base64ToBlob(base64) {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}


export type Buckets = {
    profile: {
        default: "",
        thumbnail: ""
    },
    media: {
        default: "",
    },
    post: {
        default: "",
        thumbnail: "",
    },
    posts: {
        default: "",
    },
    comments: {
        default: "",
    },
    private: {
        default: "",
    },
    shouts: {
        default: "",
    }
}

export type BucketNames = keyof Buckets

// Client-side (JavaScript)
export async function PeluchanCDN(bucket: BucketNames, { file, loading, loadOfUrl: loadOfUrl, containerInputId }: {
    file?: any,
    filename?: string,
    loadOfUrl?: string,
    loading?: () => any,
    containerInputId?: string
} = {}) {
    let formData = new FormData();

    if (file == undefined && loadOfUrl == undefined) {
        let container = document.body;
        if (containerInputId) {
            const newContainer = document.getElementById(containerInputId)
            if (newContainer) container = newContainer
        }
        // Crear un input de tipo file y agregarlo al DOM
        let input = document.createElement('input');
        input.type = 'file';
        input.style.display = "none"
        container.appendChild(input);

        // Simular un clic en el input
        input.click();

        // Esperar a que el usuario seleccione un archivo
        await new Promise(resolve => input.onchange = resolve);

        container.removeChild(input)

        // Verificar si el usuario seleccionó un archivo
        if (input.files.length == 0) {
            window.log('No se seleccionó ningún archivo')
            throw new Error('No se seleccionó ningún archivo')
        }

        formData.append("file", input.files[0]);
    }
    if (loadOfUrl != undefined) {
        formData.append("url", loadOfUrl);
    }
    if (file != undefined) {
        formData.append("file", file);
    }


    if (loading) loading()

    let res = await fetchPostFile(HOST_IMAGES_NO_NGINX + `/${bucket}/upload`, formData);
    if (res.status !== 200) {
        window.log(res.status_txt, "red")
        throw new Error(res.status_txt)
    }
    return res as UploadCDNType;
}

// Fetch helper function
async function fetchPostFile(url, formData) {
    let responseText: string
    try {
        const response = await fetch(url, {
            method: "POST",
            body: formData,
        })
        responseText = await response.text()
        return JSON.parse(responseText);
    } catch (err: any) {
        if ((err?.message || "").includes("is not valid JSON")) {
            throw new Error(responseText);
        }
        throw err
    }
}