import { HOST, HOST_IMAGES } from "../api";
import type { Buckets } from "./services/PeluchanCDN";

export const CLOUDFLARE_PROXY_R2 = {
    test: "ts",
    comments: "co",
    posts: "po",
    privates: "po",
    shouts: "po",
    temp: "tm",
    users: "us",
}

function CLOUDFLARE_PROXY_R2_BUILD(url: string) {
    const reg = /r2\:\/\/(\w*)\/(.*)/.exec(url)
    const short = CLOUDFLARE_PROXY_R2[reg[1]]
    return `https://${short}.r2.peluchan.net/${reg[2]}`
}


// Generamos el tipo que permite solo las combinaciones válidas
export type BucketPaths = {
    [K in keyof Buckets]: {
        [SubKey in keyof Buckets[K]]: `${Extract<K, string>}.${Extract<SubKey, string>}`;
    }[keyof Buckets[K]];
}[keyof Buckets];

export function IS_PELUCHAN_CDN_S2(url: string) {
    return url.indexOf("s2://") == 0
}

function PELUCHAN_CDN_S2(url: string, { varians }: { varians?: BucketPaths | BucketPaths[] } = {}) {
    const reg = /s2\:\/\/(\w*)\/(.*)/.exec(url)
    if (varians) {
        if (Array.isArray(varians)) {
            for (const va of varians) {
                const [bucket, variant] = va.split(".")
                if (bucket == reg[1]) {
                    if (variant == "default") return `${HOST_IMAGES}/${reg[1]}/${reg[2]}`
                    return `${HOST_IMAGES}/${reg[1]}/${variant}/${reg[2]}`
                }
            }
        } else {
            const [bucket, variant] = varians.split(".")
            if (bucket == reg[1]) {
                if (variant == "default") return `${HOST_IMAGES}/${reg[1]}/${reg[2]}`
                return `${HOST_IMAGES}/${reg[1]}/${variant}/${reg[2]}`
            }
        }
    }
    return `${HOST_IMAGES}/${reg[1]}/${reg[2]}`
}

export type PImageDecodeOption = { s2?: Parameters<typeof PELUCHAN_CDN_S2>[1] }

//https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/11e0e703-07f7-46ee-cab8-893eb4a1f700/public
export const MediaSource = (url?: string, { s2 }: PImageDecodeOption = {}) => {
    if (typeof url !== "string") return {
        type: "UNKNOWN",
        omit_media: false,
        url
    }

    if (url.indexOf("https://media.peluchan.net/") == 0) return {
        type: "MEDIA/PROXY",
        omit_media: false,
        url
    }
    if (url.indexOf("https://imagedelivery.net/GKo274lICZsnSFlvHgcgdw/") == 0) return {
        type: "MEDIA/CFIMG",
        omit_media: false,
        url
    }
    if (url.indexOf("r2://") == 0) {
        return {
            type: "MEDIA/R2",
            omit_media: false,
            url: CLOUDFLARE_PROXY_R2_BUILD(url)
        }
    }
    if (IS_PELUCHAN_CDN_S2(url)) {
        return {
            type: "MEDIA/S2",
            omit_media: true,
            url: PELUCHAN_CDN_S2(url, s2)
        }
    }
    return {
        type: "EXTERNO",
        omit_media: false,
        url
    }
}